<template>
  <div v-if="params != false">
    <b-row class="mt-4">
      <b-col md="12">
        <h1 class="titre">Vous payez votre mutuelle santé trop cher ?</h1>
        <p class="sous-titre">Laissez nous vous proposer votre mutuelle <strong>sur mesure</strong>.</p>
      </b-col>
    </b-row>
    <b-row class="justify-content-md-center">
      <b-col cols="12" md="8">
        <b-card class="card-search card-shadow">
          <form ref="form_lead">
            <div v-if="step === 1">
              <p class="sous-titre text-custom-primary"><strong>Souhaitez vous assurez seul ou en famille ?</strong></p>
              <div class="text-center mt-5">
                <vs-button size="xl" :color="lead.famille === false ? 'success' : 'primary'" @click="lead.famille = false; next(2)">
                  <i class="fas fa-3x fa-user text-white"></i>
                  <div>&nbsp;&nbsp;Seul(e)</div>
                </vs-button>
                <vs-button size="xl" :color="lead.famille ? 'success' : 'primary'" class="ml-5" @click="lead.famille = true; next(2)">
                  <i class="fas fa-3x fa-users text-white"></i>
                  <div>&nbsp;&nbsp;Famille</div>
                </vs-button>
              </div>
              <div class="mt-5 text-right">
                <vs-button size="large" @click="next(2)" color="primary">Suivant</vs-button>
              </div>
            </div>
            <div v-if="step === 2">
              <p class="sous-titre text-custom-primary"><strong>Informations personnel</strong></p>
              <b-row>
                <b-col md="4" class="mt-3">
                  <vs-input v-model="lead.nom" placeholder="Votre nom" color="secondary" state="primary" :required="true">
                    <template #icon>
                      <i class='fas fa-user text-primary'></i>
                    </template>
                  </vs-input>
                </b-col>
                <b-col md="4" class="mt-3">
                  <vs-input v-model="lead.prenom" placeholder="Votre prenom" color="secondary" state="primary" :required="true">
                    <template #icon>
                      <i class='fas fa-user text-primary'></i>
                    </template>
                  </vs-input>
                </b-col>
                <b-col md="4" class="mt-3">
                  <vs-input type="date" placeholder="Votre date de naissance" v-model="lead.date_naissance" :required="true" color="secondary" state="primary">
                    <template #icon>
                      <i class="fas fa-birthday-cake text-primary"></i>
                    </template>
                  </vs-input>
                </b-col>
                <b-col md="4" class="mt-3">
                  <vs-select state="primary" filter placeholder="Votre regime sociale" v-model="lead.regime_id"  :required="true">
                    <template #icon>
                      <i class="fas fa-birthday-cake text-primary"></i>
                    </template>
                    <vs-option v-for="(r, i) in params.Regime" :key="i" :label="r.titre" :value="r.id">
                      {{r.titre}}
                    </vs-option>
                  </vs-select>
                </b-col>
                <b-col md="4" class="mt-3 pt-2" v-if="lead.famille === true">
                  <vs-checkbox v-model="lead.mariee">
                    Etes-vous mariee ?
                  </vs-checkbox>
                </b-col>
                <b-col md="4" class="mt-3 pt-2" v-if="lead.famille === true">
                  <vs-checkbox v-model="lead.enfant">
                    Avez-vous des enfants ?
                  </vs-checkbox>
                </b-col>
              </b-row>
              <b-row class="mt-5">
                <b-col cols="6" class="text-left">
                  <vs-button size="large" color="secondary" border @click="next(1)">Précédent</vs-button>
                </b-col>
                <b-col cols="6" class="text-right">
                  <vs-button size="large" @click="next(lead.mariee ? 3 : lead.enfant ? 4 : 5)" color="primary">Suivant</vs-button>
                </b-col>
              </b-row>
            </div>
            <div v-if="step === 3">
              <p class="sous-titre text-custom-primary"><strong>Informations de votre conjoint</strong></p>
              <b-row>
                <b-col md="6" class="mt-3">
                  <vs-input type="date" placeholder="Date de naissance" v-model="lead.conjoint.date_naissance" :required="true" color="secondary" state="primary">
                    <template #icon>
                      <i class="fas fa-birthday-cake text-primary"></i>
                    </template>
                  </vs-input>
                </b-col>
                <b-col md="6" class="mt-3">
                  <vs-select state="primary" filter placeholder="Regime sociale" v-model="lead.conjoint.regime_id"  :required="true">
                    <template #icon>
                      <i class="fas fa-birthday-cake text-primary"></i>
                    </template>
                    <vs-option v-for="(r, i) in params.Regime" :key="i" :label="r.titre" :value="r.id">
                      {{r.titre}}
                    </vs-option>
                  </vs-select>
                </b-col>
              </b-row>
              <b-row class="mt-5">
                <b-col cols="6" class="text-left">
                  <vs-button size="large" color="secondary" border @click="next(2)">Précédent</vs-button>
                </b-col>
                <b-col cols="6" class="text-right">
                  <vs-button size="large" @click="next(lead.enfant ? 4 : 5)" color="primary">Suivant</vs-button>
                </b-col>
              </b-row>
            </div>
            <div v-if="step === 4">
              <p class="sous-titre text-custom-primary"><strong>Informations de vos enfants</strong></p>
              <b-row v-for="(e,i) in lead.enfants" :key="i">
                <b-col md="5" class="mt-3">
                  <vs-input type="date" placeholder="Date de naissance de votre enfant" v-model="e.date_naissance" :required="true" color="secondary" state="primary">
                    <template #icon>
                      <i class="fas fa-birthday-cake text-primary"></i>
                    </template>
                  </vs-input>
                </b-col>
                <b-col md="5" class="mt-3">
                  <vs-select state="primary" filter placeholder="Regime sociale de votre enfant" v-model="e.regime_id"  :required="true">
                    <template #icon>
                      <i class="fas fa-birthday-cake text-primary"></i>
                    </template>
                    <vs-option v-for="(r, i) in params.Regime" :key="i" :label="r.titre" :value="r.id">
                      {{r.titre}}
                    </vs-option>
                  </vs-select>
                </b-col>
                <b-col md="2" class="mt-3">
                  <b-button v-if="i === (lead.enfants.length-1)" variant="success" class="btn-icon" @click="addEnfant()"><i class="fas fa-plus"></i></b-button>
                  <b-button variant="danger" class="btn-icon ml-1" @click="removeEnfant(i)"><i class="fas fa-trash"></i></b-button>
                </b-col>
              </b-row>
              <b-row class="mt-5">
                <b-col cols="6" class="text-left">
                  <vs-button size="large" color="secondary" border @click="next(lead.mariee ? 3 : 2)">Précédent</vs-button>
                </b-col>
                <b-col cols="6" class="text-right">
                  <vs-button size="large" @click="next(5)" color="primary">Suivant</vs-button>
                </b-col>
              </b-row>
            </div>
            <div v-if="step === 5">
              <p class="sous-titre text-custom-primary"><strong>N'oublions pas bien sur</strong></p>
              <b-row>
                <b-col md="4" class="mt-3">
                  <vs-input placeholder="Votre telephone" v-model="lead.telephone" :required="true" color="secondary" state="primary">
                    <template #icon>
                      <i class="fas fa-phone text-primary"></i>
                    </template>
                  </vs-input>
                </b-col>
                <b-col md="4" class="mt-3">
                  <vs-input placeholder="Votre email" v-model="lead.email" :required="true" color="secondary" state="primary">
                    <template #icon>
                      <i class="fas fa-at text-primary"></i>
                    </template>
                  </vs-input>
                </b-col>
                <b-col md="4" class="mt-3">
                  <vs-input placeholder="Votre code postal" v-model="lead.cp" :required="true" color="secondary" state="primary">
                    <template #icon>
                      <i class="fas fa-city text-primary"></i>
                    </template>
                  </vs-input>
                </b-col>
              </b-row>
              <b-row class="mt-5">
                <b-col cols="6" class="text-left">
                  <vs-button size="large" color="secondary" border @click="next(lead.enfant ? 4 : lead.mariee ? 3 : 2)">Précédent</vs-button>
                </b-col>
                <b-col cols="6" class="text-right">
                  <vs-button size="large" @click="next(6)" color="primary">Suivant</vs-button>
                </b-col>
              </b-row>
            </div>
            <div v-if="step === 6">
              <p class="sous-titre text-custom-primary"><strong>Merci de patientez, nous rechecrchons les meilleurs offres !</strong></p>
              <div class="text-center mt-5">
                <b-spinner variant="primary" type="grow"  style="width: 6rem; height: 6rem;"></b-spinner>
              </div>
            </div>
          </form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "",
  components: {
  },
  data: () => ({
    params: false,
    step:1,
    lead:{
      "famille":null,
      "mariee":false,
      "enfant":false,
      "nom": "",
      "prenom": "",
      "email": "",
      "telephone": "",
      "mobile": "",
      "date_naissance": null,
      "date_effet": null,
      "cp": "",
      "regime_id": "",
      "conjoint": {
        "regime_id": "",
        "date_naissance": null
      },
      "enfants": [],
      "mutuelle_id": []
    }
  }),
  watch:{
    'lead.enfant'(v){
      this.lead.enfants = [];
      if(v === true){
        this.addEnfant();
      }
    },
    step(v){
      if(v === 6){
        this.save();
      }
    }
  },
  methods: {
    next(i) {
      if( i > this.step && this.$refs.form_lead.reportValidity() === false){
        return false;
      }
      this.step = i
    },
    addEnfant(){
      this.lead.enfants.push({
        date_naissance:null,
        regime_id:""
      });
    },
    removeEnfant(i){
      this.lead.enfants.splice(i,1)
    },
    save(){
      this.$api.ajax('https://api.laf.jg.app/d/lead', this.lead, res => {
        if(res.status === true){
          this.$router.push({path:"/recherche/"+res.data})
        }
      })
    },
  },
  beforeMount() {
    this.$api.ajax('https://api.laf.jg.app/d/params', null, res => {
      if(res.status === true){
        this.params = res.data;
      }
    });
    if(this.$route.query.lead != undefined){
      this.lead = JSON.parse(this.$route.query.lead)
    }
  }
}
</script>
<style scoped>
.titre {
  font-size: 40px !important;
  font-weight: 600;
  color: #fff;
  margin-top: 25px;
  text-align: left;
}
@media (max-width: 768px){
  .titre{
    font-size:30px !important;
  }
}
.sous-titre {
  font-weight: 300;
  font-size: 25px !important;
  color: #fff;
}
.text-custom-primary{
  color: #3b8aff !important;
  font-weight: 300;
}
</style>
